(<template>
  <form-template :title="title"
                 :progress="progressActive">
    <div class="add-phone">
      <sk-note v-if="isVerificationCodeSent"
               class="add-phone__verification-sent"
               :text="verificationCodeSent" />
      <div class="add-phone__phone-number-wrapper">
        <div class="add-phone__phone-number-fields-wrapper"
             @keydown.enter.stop.prevent="sendPhone">
          <sk-select :type="'countryCodes'"
                     :description="phoneCodeText"
                     :default-value="phoneCodeText"
                     :preselected-value="phoneCode"
                     :disabled="disablePhoneCodeField"
                     :mob-numeric-keyboard="true"
                     class="add-phone__phone-code"
                     @csvaluechanged="setPhoneCode" />
          <sk-input :description="phoneNumberDescr"
                    :autofocus="true"
                    :preselected-value="phoneNumber"
                    :placeholder="phoneNumberPlaceholder"
                    :validation-name="phoneNumberErrorsName"
                    :type="'tel'"
                    :reg-exp="/[^0-9]/g"
                    :mob-numeric-keyboard="true"
                    class="add-phone__phone-number"
                    @fieldvaluechanged="setPhoneNumber" />
          <button :disabled="disablePhoneBtn"
                  class="sk-btn sk-btn--default add-phone__send-phone-btn"
                  @click="sendPhone">{{ sendPhoneBtnText }}</button>
        </div>
        <p v-if="sendVerificationRequestTimer != 0"
           class="add-phone__interval-counter"
           v-html="smsIntervalText"></p>
      </div>
      <label class="add-phone__code-label"
             @keydown.enter.stop.prevent="sendCode">
        <p class="add-phone__code-text">{{ $gettext('Enter verification code') }}</p>
        <input ref="codeInput"
               :value="code"
               :type="'tel'"
               pattern="[0-9]*"
               inputmode="numeric"
               class="add-phone__code-input"
               @input="catchCode"
               @focus="setCode('')">
        <span class="add-phone__code-input-imitation"></span>
        <span class="add-phone__code-input-imitation"></span>
        <span class="add-phone__code-input-imitation"></span>
        <span class="add-phone__code-input-imitation"></span>
      </label>
      <div v-if="codeErrors && codeErrors.length"
           class="add-phone__code-errors-cont">
        <p v-for="(error, index) in codeErrors"
           :key="index">{{ error }}</p>
      </div>
      <button ref="verifyBtn"
              :disabled="disableVerifyBtn"
              class="sk-btn sk-btn--default add-phone__submit-btn"
              @click="sendCode">{{ $gettext('Continue') }}</button>
      <button v-if="canSkipStep"
              class="sk-btn sk-btn--white add-phone__skip-btn"
              @click="nextStep">{{ $gettext('Skip') }}</button>
    </div>
  </form-template>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';

  export default {
    components: {
      'form-template': WizardFormTemplate
    },
    data() {
      return {
        isVerificationCodeSent: false,
        progressActive: false,
        sendVerificationRequestTimer: 0
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsInterpreter'
      ]),
      ...mapState('TTWizardStore', {
        phoneCode: (state) => state.stepsData.phone.phoneCode,
        phoneNumber: (state) => state.stepsData.phone.phoneNumber,
        phoneNumberErrors: (state) => state.errors.phoneNumber.errors,
        phoneNumberErrorsName: (state) => state.errors.phoneNumber.name,
        code: (state) => state.stepsData.phone.code,
        codeErrors: (state) => state.errors.code.errors,
        codeErrorsName: (state) => state.errors.code.name
      }),
      ...mapState('UserInfoStore', {
        emailVerified: ({mainUserInfo}) => mainUserInfo.emailVerified,
        forEnterprise: ({mainUserInfo}) => mainUserInfo.forEnterprise
      }),
      disablePhoneCodeField() {
        return this.userIsInterpreter && this.phoneCode === '+47';
      },
      requiredPhoneTitle() { return this.$gettext('Verify your phone number'); },
      enterPhoneTitle() { return this.$gettext('Enter your phone number'); },
      phoneCodeText() { return this.$gettext('Country code'); },
      phoneNumberDescr() { return this.$gettext('Phone number'); },
      phoneNumberPlaceholder() { return this.$gettext('Number'); },
      canSkipStep() { return !this.userIsInterpreter; },
      title() { return this.forEnterprise ? this.enterPhoneTitle : this.requiredPhoneTitle; },
      sendPhoneBtnText() { return this.sendVerificationRequestTimer != 0 ? this.$gettext('Sent') : this.$gettext('Send code'); },
      disablePhoneBtn() {
        return !this.phoneCode.trim()
          || !this.phoneNumber.trim()
          || (this.sendVerificationRequestTimer != 0);
      },
      disableVerifyBtn() {
        return !this.code.trim()
          || this.code.trim().length < 4
          || !this.phoneCode.trim()
          || !this.phoneNumber.trim()
          || !this.isVerificationCodeSent;
      },
      smsIntervalText() {
        const template = this.$gettext('If you don\'t receive the sms in <b>0:%{timer}</b><br> seconds, you can request another code.');
        return this.$gettextInterpolate(template, {timer: this.sendVerificationRequestTimer});
      },
      verificationCodeSent() { return this.$gettext('Verification code has been sent to your phone.'); }
    },
    watch: {
      code() {
        if (this.code.length > 3) {
          this.$refs.codeInput.blur();
          this.removeOneError('code');
        }
      },
      disableVerifyBtn(newVal) {
        if (!newVal) {
          setTimeout(() => {
            this.$refs.verifyBtn.focus();
          });
        }
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', [
        'setError',
        'removeOneError',
        'removeErrors',
        'setPhoneCode',
        'setPhoneNumber',
        'setCode'
      ]),
      catchCode(eve) {
        this.setCode(eve.target.value);
      },
      isAnyPhoneError() {
        this.removeErrors();
        let indicator = false;
        if (!this.phoneNumber.trim()) {
          this.setError({
            fieldName: 'phoneNumber',
            errorText: this.$gettext('Please enter a phone number')
          });
          indicator = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrorsName, errors: this.phoneNumberErrors});

        return indicator;
      },
      isAnyCodeError() {
        this.removeErrors();
        let indicator = false;
        if (!this.code.trim() || this.code.trim().length < 4) {
          this.setError({
            fieldName: 'code',
            errorText: this.$gettext('The code should contain 4 digits')
          });
          indicator = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.codeErrorsName, errors: this.codeErrors});

        return indicator;
      },
      countDownVerificationTimer() {
        if (this.sendVerificationRequestTimer > 0) {
          setTimeout(() => {
            this.sendVerificationRequestTimer -= 1;
            if (this.sendVerificationRequestTimer < 10) this.sendVerificationRequestTimer = '0' + this.sendVerificationRequestTimer;
            this.countDownVerificationTimer();
          }, 1000);
        }
      },
      sendPhone() {
        if (!this.isAnyPhoneError()) {
          this.progressActive = true;

          const form = new FormData();

          form.append('phone_code', this.phoneCode.trim());
          form.append('phone_number', this.phoneNumber.trim());

          this.$store.dispatch('sendPhoneVerificationSMS', form).then(() => {
            this.progressActive = false;
            this.sendVerificationRequestTimer = 60;
            this.isVerificationCodeSent = true;
            this.countDownVerificationTimer();
            this.$refs.codeInput.focus();
          }).catch((error) => {
            this.progressActive = false;
            if (error && error.errors && error.errors.person) {
              error.errors = error.errors.person;
            }
            if (error
              && error.errors
              && error.errors.phone_number
              && error.errors.phone_number.length
              && error.errors.phone_number.indexOf('Invalid phone') !== -1) {
              this.setError({
                fieldName: 'phoneNumber',
                errorText: this.$gettext('Please enter a valid phone number.')
              });
              this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberErrorsName, errors: this.phoneNumberErrors});
            }
          });
        }
      },
      sendCode() {
        if (!this.isAnyCodeError() && !this.disableVerifyBtn) {
          this.progressActive = true;
          this.$store.dispatch('sendSMSCode', this.code).then((res) => {
            if (res.verified) {
              this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
                this.nextStep();
              });
            } else {
              this.handleCodeError();
            }
          }).catch(() => {
            this.handleCodeError();
          });
        }
      },
      handleCodeError() {
        this.progressActive = false;
        this.setError({
          fieldName: 'code',
          errorText: this.$gettext('The code is not valid')
        });
        this.$store.commit('ErrorsStore/setError', {name: this.codeErrorsName, errors: this.codeErrors});
      },
      nextStep() {
        let nextStep = '';
        if (this.emailVerified) {
          nextStep = 'BuyerHome';
        } else if (this.forEnterprise) {
          nextStep = 'BuyerRegisterEnterprise';
        } else if (this.userIsInterpreter) {
          nextStep = 'ServerProfileEdit';
        } else {
          nextStep = 'BuyerWizardVerifyEmail';
        }
        this.$router.push(this.$makeRoute({name: nextStep, params: {id: this.userUid}}));
      }
    }
  };
</script>

<style>
  @media (max-width: 500px) {
    .add-phone__phone-code .sk-select {
      padding-right: 0;
    }

    .add-phone__phone-code .sk-select__arrow {
      display: none;
    }
  }
</style>

<style scoped>
  .add-phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .add-phone__explanation {
    width: 315px;
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 12px;
    text-align: center;
  }

  .add-phone__verification-sent {
    margin-bottom: 16px;
  }

  .add-phone__phone-number-wrapper {
    display: flex;
    flex-direction: column;
  }

  .add-phone__phone-number-fields-wrapper {
    display: flex;
    width: 385px;
    max-width: 100%;
  }

  .add-phone__phone-code {
    flex-shrink: 0;
    width: 100px;
    margin-right: 10px;
    text-align: left;
  }

  .add-phone__phone-number {
    flex-grow: 1;
    margin-bottom: 5px;
  }

  .add-phone__send-phone-btn {
    display: inline-block;
    flex-shrink: 0;
    width: auto;
    margin-top: 22px;
    margin-left: 10px;
    padding: 0 10px;
  }

  .add-phone__interval-counter {
    margin-left: auto;
    color: #333;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
  }

  .add-phone__code-label {
    position: relative;
    display: block;
    width: 230px;
    max-width: 100%;
    margin-top: 40px;
  }

  .add-phone__code-text {
    width: 100%;
    margin-bottom: 5px;
    font-size: 12px;
    text-align: center;
  }

  .add-phone__code-input {
    position: relative;
    z-index: 1;
    width: 115%;
    height: 35px;
    padding: 0 0 0 22px;
    border: 0;
    background-color: transparent;
    letter-spacing: 52px;
  }

  .add-phone__code-input-imitation {
    position: absolute;
    bottom: 0;
    display: block;
    width: 50px;
    height: 35px;
    border: solid 1px #a7abbd;
  }

  .add-phone__code-input-imitation:nth-of-type(1) {
    left: 0;
  }

  .add-phone__code-input-imitation:nth-of-type(2) {
    left: 60px;
  }

  .add-phone__code-input-imitation:nth-of-type(3) {
    right: 60px;
  }

  .add-phone__code-input-imitation:nth-of-type(4) {
    right: 0;
  }

  .add-phone__skip-btn {
    width: 100px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .add-phone__submit-btn {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .add-phone__code-errors-cont {
    display: block;
    width: 230px;
    margin-top: 10px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
    text-align: left;
  }

  @media (max-width: 767px) {
    .add-phone__send-phone-btn {
      margin-top: 18;
    }
  }

  @media (max-width: 500px) {
    .add-phone {
      padding: 0 15px;
    }

    .add-phone__phone-code {
      width: 50px;
    }

    .add-phone__code-input-imitation {
      border: 0;
      border-bottom: solid 1px #a7abbd;
    }
  }
</style>
